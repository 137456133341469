<template>
  <div>
    <section id="top">
      <div>
        <ul class="pillars notype">
          <li><span>「響く」</span>企画</li>
          <li class="times">×</li>
          <li><span>「使える」</span>デザイン</li>
          <li class="times">×</li>
          <li><span>「確かな」</span>モノづくり</li>
        </ul>
        <p class="ctr em">エレクトロドリームは、<br>あなたの会社のデジタルコミュニケーション促進をお手伝いします。</p>
      </div>
      <a class="start" href="#service">START</a>
    </section>

    <section id="service">
      <header>
        <h2><span>Service</span>- サービス -</h2>
        <p class="nm">トータルなご提案で、「モノづくり」による目標達成を支援します。</p>
      </header>
      <div class="inner">
        <div class="flow">
          <ul class="notype">
            <li>
              <h3>戦略立案</h3>
              <p>何のための「モノづくり」かを顕在化し、ブレないプロジェクト支援を行います。</p>
            </li>
            <li>
              <h3>企画</h3>
              <p>金融/保険/教育/エンタメなど多彩なジャンルの案件を手がけることで得られる幅広い経験値と、 自社サービス運営で培ったノウハウを活かし、「響く」企画提案・サービス設計をお手伝いします。</p>
            </li>
            <li>
              <h3>デザイン</h3>
              <p>開発チームと連携することでプラットフォームやOS毎に異なる特性や開発事情を考慮しつつ、何がユーザにとって本当に「使える」ものなのかを考え、画面とそこから得られる体験（UI/UX）を設計・デザインします。</p>
            </li>
            <li>
              <h3>開発</h3>
              <p>デザインチームと連携し、システムとの連携、組み込みを見据えたフロントエンド設計・開発を行います。また、リリース後修正できないCD-ROMからスタートしている企業文化からリリース品質にはこだわります。</p>
            </li>
            <li>
              <h3>運用保守</h3>
              <p>作った「モノ」の安定運用、最適化はもちろん、価値を向上させるための改善提案も積極的に実施します。</p>
            </li>
            <li>改善提案</li>
          </ul>
        </div>
      </div>
      <div class="specialities">
        <h3><span>Our Specialities</span>- 得意分野 -</h3>
        <p>業務システムUX/UIデザイン、フロントエンド開発<br>
          コーポレートサイト企画・制作<br>
          ECサイト構築および運用<br>
          採用サイト、キャンペーンサイト企画・制作<br>
          SNS、デジタルマーケティング企画・運用<br>
          コンシューマ向けスマホアプリ開発<br>
          教育・語学学習スマホアプリ企画・開発<br>
          店舗や営業マン利用のタブレットアプリ企画・開発<br>
          IT導入補助金等の申請サポート<br>
          地域活性化や農業分野でのITコンサルティング</p>
        <p><router-link to="/works" class="btn btn-round"><i class="fa-solid fa-tags"></i>&nbsp;制作実績はこちら</router-link></p>
      </div>
    </section>

    <section id="others">
      <header>
        <h2><span>Internal Ventures</span>- 新しい事業・取り組み -</h2>
        <p>私たちは、受託制作以外にもいろいろなチャレンジを行っています。</p>
      </header>
      <div class="other-cards">
        <!-- card -->
        <a href="https://cocopon.app/" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/cocopon.png" alt=""></figure>
            <div class="link-category works">自社サービス</div>
            <h3>スタンプカードアプリ<span>COCOPON</span></h3>
            <p>小学校の夏休みにスタンプカードを持ってラジオ体操に参加した思い出がある方は多いのではないでしょうか。そのスタンプカードのように、押してもらえる嬉しさと、ご褒美のために集める楽しさを感じながら、利用できるアプリです。</p>
          </article>
        </a>
        <!-- card -->
        <a href="https://cocoil.app/" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/cocoil.png" alt=""></figure>
            <div class="link-category works">自社サービス</div>
            <h3>位置情報共有サービス<span>ココイル</span></h3>
            <p>会社のイベントで現地集合する際、わざわざ連絡せずにお互いの状況を知りたいという、社員の「欲しい」から生まれたサービスです。地図上に表示されるアバターで居場所を確認しながらコミュニケーションを楽しむことが出来ます。</p>
          </article>
        </a>

        <!-- card -->
        <a href="https://itunes.apple.com/jp/app/japanese-nature-photo-clock/id337529082?mt=8" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/jnpc.png" alt=""></figure>
            <div class="link-category works">自社アプリ</div>
            <h3>写真集＆時計機能アプリ<span>Japanese Nature Photo Clock</span></h3>
            <p>長野県松本在住の自然写真家、務台直樹が5年の歳月をかけて撮りためた美しい日本の写真と、時計＆カレンダーを組み合わせた、「だれもが癒される」写真集＆時計機能アプリです。</p>
          </article>
        </a>

        <!-- card -->
        <a href="http://www.electrodream.jp/otsuki/" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/otukibase.png" alt=""></figure>
            <div class="link-category csr">地域 × IT</div>
            <h3><span>おおつきベース</span></h3>
            <p>山梨県大月市にある「大月エコの里」に畑を借り、おおつきベースと名付けました。地元のシニアの皆さんと、｢農業×地域×IT｣をテーマとした活動をしています。定期的にイベントなども開催しておりますので、ぜひ一度お越しください。</p>
          </article>
        </a>

        <!-- card -->
        <a href="https://www.wantedly.com/companies/electrodream/post_articles/85199" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/jyouhoucafe.png" alt=""></figure>
            <div class="link-category csr">高齢者 × IT</div>
            <h3><span>じょうほうカフェ</span></h3>
            <p>社会福祉法人逗子市社会福祉協議会と連携したITサロン活動「じょうほうカフェ」を開催しています。スマートフォンやタブレット、パソコンのわからないことを地域の高齢者、障害のある方にわかりやすくレクチャーしています。</p>
          </article>
        </a>

        <!-- card -->
        <a href="https://shizensaien.shop/" target="_blank" rel="noopener noreferrer">
          <article class="others-card">
            <figure><img src="@/assets/images/home/shizensaien.png" alt=""></figure>
            <div class="link-category csr">脱炭素 × IT</div>
            <h3><span>自然菜園LifeStyle</span></h3>
            <p>「環境保全型家庭菜園のある暮らし」を提唱し、カーボンボジティブで環境に優しいライフスタイルの情報発信、ECサイト運営を行っています。</p>
          </article>
        </a>
      </div>
      <div class="other-banners">
        <a href="/green-business/" target="_blank" rel="noopener"><figure><img src="@/assets/images/home/bnr-gx.png" alt="グリーンビジネス"></figure></a>
        <a href="http://ec.electrodream.shop/" target="_blank" rel="noopener"><figure><img src="@/assets/images/home/bnr-ec.png" alt="ECサイト構築"></figure></a>
        <a href="http://it-agri.electrodream.co.jp/" target="_blank" rel="noopener"><figure><img src="@/assets/images/home/bnr-itagri.png" alt="IT × 農業"></figure></a>
      </div>
    </section>

    <section id="contact">
      <header>
        <h2><span>Contact us</span>- お問い合わせ -</h2>
        <p>株式会社エレクトロドリームへのお問い合わせ、ご質問、御見積のご依頼など、<br>
        お気軽にご相談くださいませ。<br>
        後日担当者よりご回答申し上げます。</p>
      </header>
      <p class="mt50"><router-link to="/contact" class="btn btn-round btn-round-rev btn-round-l"><i class="fa-solid fa-envelope"></i>&nbsp;お問い合わせ</router-link></p>
    </section>

    <section id="company">
      <div id="gmap"></div>
      <!-- <ul class="ctrl">
        <li :class="{current: current_office === 'meguro' }" @click="setCurrent('meguro')">東京本社</li>
        <li :class="{current: current_office === 'fujisawa' }" @click="setCurrent('fujisawa')">湘南オフィス</li>
        <li :class="{current: current_office === 'meguro' }">東京本社</li>
        <li :class="{current: current_office === 'fujisawa' }">湘南オフィス</li>
      </ul> -->
      <div class="profile">
        <h2><span>Company</span>- 会社概要 -</h2>
        <dl>
          <dt><span>社名</span></dt>
          <dd>株式会社エレクトロドリーム&emsp;<br class="sp">英文社名：electrodream, Inc.</dd>
          <dt><span>役員構成</span></dt>
          <dd>代表取締役：務台祐太郎&emsp;<br class="sp">取締役：滝澤渉、松本健一郎<br>社外取締役：前田俊秀、藤原雅生&emsp;<br class="sp">監査役：新井儀晴</dd>
          <dt><span>設立</span></dt>
          <dd>2001年7月16日<br class="sp">（2003年10月27日に株式会社へ組織変更）</dd>
          <dt><span>資本金</span></dt>
          <dd>1000万円</dd>
          <dt><span>所在地</span></dt>
          <dd>〒135-0011 東京都江東区扇橋2ｰ9ｰ10-102</dd>
          <dt><span>業務目的</span></dt>
          <dd>ウェブサイト、スマートデバイスアプリケーション企画・開発・運用・コンサルティング</dd>
          <dt><span>取引銀行</span></dt>
          <dd>三井住友銀行渋谷駅前支店、三菱UFJ銀行渋谷支店、東日本銀行渋谷支店</dd>
          <dt><span>関連会社</span></dt>
          <dd>株式会社ブレイン、サイファー株式会社、有限会社テンパス・フュジット</dd>
          <dt><span>最近の年商</span></dt>
          <dd>第21期：1億3073万円&emsp;<br class="sp">第22期：1億6470万円&emsp;<br class="sp">第23期：1億6792万円</dd>
          <dt><span>主要取引先</span></dt>
          <dd>株式会社アドバンテッジ リスクマネジメント<br>
            SCSK株式会社<br>
            株式会社QUICK<br>
            住友三井オートサービス株式会社<br>
            株式会社壮関<br>
            ソースネクスト株式会社<br>
            ソフトバンク株式会社<br>
            株式会社 千乃コーポレーション<br>
            株式会社DNPコミュニケーションデザイン<br>
            株式会社ドーム<br>
            株式会社ブレイン<br>
            株式会社ベネッセコーポレーション<br>
            株式会社メディックメディア<br>
            株式会社YAMAGIWA<br>
            株式会社ヤマハミュージックエンタテインメントホールディングス<br>
            株式会社ロードアンドスカイ・オーガニゼーション</dd>
        </dl>
        <span class="fs14 mb30"><a href="/static/pdf/company_profile.pdf" target="_black">会社案内ダウンロード</a></span>
      </div>
      <div class="addr">
        <div>
          <span>ただいまフルリモート体制で運営しております</span><br>リモートでもエキサイティングなサービスを生み出します
        </div>
      </div>
    </section>
  </div>
</template>

<script>
let scriptjs = require('scriptjs')

export default {
  name: 'HomeView',
  data: () => {
    return {
      mapObj: null,
      // center: { lat: 37.5350017, lng: 132.2451511 },
      centerPC: { lat: 38.167542, lng: 132.2451511 },
      centerSP: { lat: 52.4501605, lng: 138.917070 },
      zoomPC: 6,
      zoomSP: 5,
      maxZoom: 7,
      minZoom: 5
    }
  },
  methods: {
    initMap: function () {
      const google = window.google

      // オプション
      let mapOptions = {
        zoom: (window.matchMedia('(max-width: 960px)').matches) ? this.zoomSP : this.zoomPC,
        center: (window.matchMedia('(max-width: 960px)').matches) ? this.centerSP : this.centerPC,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false, //マップタイプ コントロール
        fullscreenControl: false, //全画面表示コントロール
        streetViewControl: false, //ストリートビュー コントロール
        zoomControl: false, //ズーム コントロール
      }
      this.mapObj = new google.maps.Map(document.getElementById('gmap'), mapOptions)

      // marker
      let markers = [
        { img: '01.png', latlng: { lat: 35.718974, lng: 138.395674 } },
        { img: '02.png', latlng: { lat: 34.216284, lng: 141.624698 } },
        { img: '03.png', latlng: { lat: 37.010611, lng: 139.656531 } },
        { img: '04.png', latlng: { lat: 40.039343, lng: 141.178785 } },
        { img: '05.png', latlng: { lat: 35.046239, lng: 138.818252 } },
        { img: '06.png', latlng: { lat: 34.355197, lng: 135.028263 } },
        { img: '07.png', latlng: { lat: 34.380429, lng: 137.811374 } },
        { img: '08.png', latlng: { lat: 41.485498, lng: 140.251583 } },
        { img: '09.png', latlng: { lat: 38.304248, lng: 140.978893 } },
        { img: '10.png', latlng: { lat: 35.670862, lng: 141.017604 } },
        { img: '11.png', latlng: { lat: 37.088541, lng: 137.073311 } },
        { img: '12.png', latlng: { lat: 35.333473, lng: 136.119980 } }
      ]
      const setMarker = (img, latlng) => {
        const opt = {
          position: latlng,
          map: this.mapObj,
          icon: {
            url: 'static/img/avatar/' + img,
            scaledSize: new google.maps.Size(70, 70)
          }
        }
        return new google.maps.Marker(opt)
      }
      for (let i = 0; i < markers.length; i++) {
        setMarker(markers[i].img, markers[i].latlng)
      }

      window.addEventListener('resize', () => {
        if (window.matchMedia('(max-width: 960px)').matches) {
          this.mapObj.setZoom(this.zoomSP)
          this.mapObj.setCenter(this.centerSP)
        } else {
          this.mapObj.setZoom(this.zoomPC)
          this.mapObj.setCenter(this.centerPC)
        }
      })
    },
    setCurrent: function (office) {
      this.current_office = office
      this.initMap()
    },
    checkDevice: function () {
      let oldDevice = this.current_device
      if (window.matchMedia('(max-width: 767px)').matches) {
        // スマホ表示時にマップのセンター座標を変更する
        this.current_device = 'sp'
      } else if (window.matchMedia('(max-width: 962px)').matches) {
        this.current_device = 'tab'
      } else {
        this.current_device = 'pc'
      }
      if (this.current_device !== oldDevice) {
        // 地図のセンターとズームを調整
        if (this.mapObj) {
          this.mapObj.panTo(this['center_' + this.current_office + '_' + this.current_device])
        }
      }
    }
  },
  created: function () {
  },
  mounted: function () {
    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-794348514')

    window.addEventListener('resize', this.checkDevice)
    let _self = this
    scriptjs(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyByo4AoFUFKAiYtlcscdMM3aDH0I7ZaS8U',
      'loadGoogleMap'
    )
    scriptjs.ready('loadGoogleMap', () => {
      _self.checkDevice()
      _self.initMap()
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  position: relative;
}
section h2 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.125;
  text-align: center;
}
section h2 span {
  display: block;
  font-size: 3.75rem;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  section h2 {
    font-size: 1rem;
  }
  section h2 span {
    font-size: 2rem;
  }
}

/* TOP */
#top {
  padding: 30px 192px 100px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  min-height: 500px;
  background-color: #fff;
}
  #top p {
    font-size: 1.4rem;
  }
/* pillars */
.pillars {
  position: relative;
  margin: 40px auto 40px;
  display: flex;
  justify-content: space-between;
}
  .pillars li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 240px;
    height: 240px;
    background-color: #ffb900;
    border-radius: 50%;
    font-size: 2.3275rem;
    letter-spacing: -0.125rem;
    line-height: 1.2;
    color: #000;
    text-align: center;
    transition: .3s;
  }
  .pillars li.times {
    flex-shrink: 1;
    width: 40px;
    height: auto;
    background-color: transparent;
    font-size: 3.75rem;
    color: #631a19;
    text-align: center;
  }
    .pillars li span {
      display: block;
      margin: 0 auto;
      font-size: 2.9743rem;
      letter-spacing: 0;
      line-height: 1.4;
      transition: .3s;
    }
  @media screen and (max-width: 1086px) {
    .pillars li {
      width: 22vw;
      height: 22vw;
      font-size: 3.25vw;
    }
    .pillars li span {
      font-size: 4.25vw;
    }
  }
  @media screen and (max-width: 768px) {
    #top {
      padding: 0 20px 40px;
      height: auto;
      min-height: auto;
    }
    #top p {
      margin-top: 2vh;
      font-size: 4vw;
    }
    .pillars {
      margin: 10px auto;
      flex-direction: column;
      justify-content: center;
      align-items: center
    }
    .pillars li {
      width: 29vw;
      height: 29vw;
      font-size: 4.75vw;
    }
    .pillars li span {
      font-size: 5.75vw;
    }
    .pillars li.times {
      margin: -1.5vw 0 -0.75vw;
      font-size: 5vw;
    }
  }
.start {
  display: block;
  position: absolute;
  bottom: 10px;
  left: calc((100% - 136px) / 2);
  font-size: 1.25rem;
  text-align: center;
  text-decoration: none;
  transform: translateX(-50%);
}
  .start::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: #631a19;
    border-top-width: 20px;
    border-bottom: none;
  }
  .start:hover {
    color: #ffb900;
    animation:bounce 2s ease infinite normal 0s none running;
    -webkit-animation:bounce 2s ease infinite normal 0s none running;
    -ms-animation:bounce 2s ease infinite normal 0s none running;
  }
  @keyframes bounce {
    0%, 100%, 10%, 40%, 70% {transform: translate(-50%, 0px);}
    30% {transform: translate(-50%, -30px);}
    50% {transform: translate(-50%, -15px);}
  }
  @-webkit-keyframes bounce {
    0%, 100%, 10%, 40%, 70% {transform: translate(-50%, 0px);}
    30% {transform: translate(-50%, -30px);}
    50% {transform: translate(-50%, -15px);}
  }
  .start:hover::after {
    border-top-color: #ffb900;
  }
  @media screen and (max-width: 768px) {
    .start {
      display: none;
  }
}

/* service */
#service {
  background: #f8f5f0 url(/static/img/0fd7d19c774bef98e2ead54b447454a9_l.png) no-repeat left top;
}
  #service .inner {
    padding-right: 136px;
  }
  #service header {
    padding: 95px 196px 30px 60px;
    background: #f08c00 url(/static/img/0fd7d19c774bef98e2ead54b447454a9_l.png) no-repeat left top;
    background-blend-mode: multiply;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  /* for IE10,11 and Edge */
    _:-ms-lang(x), #service header {
      background: rgba(240,140,0,.9);
    }
  /* */
    #service header h2 {
      margin: 0 0 20px;
      color: #fff;
    }
    .flow {
      position: relative;
      margin: 0 auto 60px;
      margin-bottom: 60px;
      padding: 40px 60px 0 246px;
      max-width: 1000px;
      transition: .3s;
    }
    .flow::before {
      position: absolute;
      left: 60px;
      bottom: -4px;
      content: '';
      display: block;
      width: 296px;
      height: 765px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      transition: .3s;
    }
      .flow ul {
        transition: .3s;
      }
      .flow li {
        padding-bottom: 30px;
        padding-left: 240px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 220px;
      }
      .flow li:nth-of-type(1) {
        min-height: 200px;
        background: url(/static/img/flow01.png) no-repeat left top;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
      }
      .flow li:nth-of-type(5) {
        min-height: 198px;
        background: url(/static/img/flow05.png) no-repeat left top;
      }
      .flow li:nth-of-type(6) {
        display: none;
      }
      .flow h3 {
        margin: 0;
        font-size: 2.25rem;
        color: #f08c00;
      }
      .flow p {
        margin: 0;
        font-size: 1.125rem;
        line-height: 1.22;
        color: #631a19;
      }
    .specialities {
      position: relative;
      padding: 60px 136px 80px 0;
      background-color: #edebe6;
      font-size: 1.5rem;
      line-height: 1.25;
      text-align: center;
      overflow: hidden;
    }
      .specialities::before {
        position: absolute;
        top: 0;
        left: calc((100% - 136px) / 2);
        content: '';
        display: block;
        width: 56px;
        height: 56px;
        background-color: #f8f5f0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .specialities h3 {
        font-size: 1.125rem;
      }
        .specialities h3 span {
          display: block;
        font-size: 2.25rem;
        }
      .specialities p {
        color: #333;
      }
  @media screen and (max-width: 1024px) {
    .flow {
      padding-left: 206px;
    }
    .flow::before {
      width: 256px;
    }
      .flow h3 {
        font-size: 2rem;
      }
      .flow p {
        font-size: 1rem;
      }
  }
  @media screen and (max-width: 920px) {
    .flow {
      padding-left: 160px;
    }
    .flow::before {
      width: 189px;
      height: 624px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 237px 613px;
    }
      .flow li {
        padding-left: 192px;
        min-height: 176px;
      }
      .flow li:nth-of-type(1) {
        min-height: 160px;
        background: url(/static/img/flow01.png) no-repeat left top;
        background-size: 172px auto;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
        background-size: 172px auto;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
        background-size: 172px auto;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
        background-size: 172px auto;
      }
      .flow li:nth-of-type(5) {
        min-height: 158px;
        background: url(/static/img/flow05.png) no-repeat left top;
        background-size: 172px auto;
      }
      .flow h3 {
        font-size: 1.6rem;
      }
      .flow p {
        font-size: .9rem;
      }
  }
  @media screen and (max-width: 860px) {
    .flow {
      padding-right: 30px;
      padding-left: 120px;
    }
    .flow::before {
      left: 30px;
      width: 166px;
      height: 556px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 215px 556px;
    }
      .flow li {
        padding-left: 168px;
        min-height: 160px;
      }
      .flow li:nth-of-type(1) {
        min-height: 140px;
        background: url(/static/img/flow01.png) no-repeat left top;
        background-size: 150px auto;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
        background-size: 150px auto;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
        background-size: 150px auto;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
        background-size: 150px auto;
      }
      .flow li:nth-of-type(5) {
        min-height: 138.6px;
        background: url(/static/img/flow05.png) no-repeat left top;
        background-size: 150px auto;
      }
    .specialities {
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: 768px) {
    #service .inner {
      padding-right: 0;
    }
    #service header {
      padding: 80px 20px 30px 20px;
      font-size: 1rem;
    }
    #service,
    #service header {
      background-size: 110% auto;
    }
    .flow {
      padding: 40px 20px 0 110px;
    }
    .flow::before {
      left: 30px;
      width: 146px;
      height: 515px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 199px 515px;
    }
      .flow li {
        padding-left: 148px;
        min-height: 150px;
      }
      .flow li:nth-of-type(1) {
        min-height: 120px;
        background: url(/static/img/flow01.png) no-repeat left top;
        background-size: 129px auto;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
        background-size: 129px auto;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
        background-size: 129px auto;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
        background-size: 129px auto;
      }
      .flow li:nth-of-type(5) {
        min-height: 118.8px;
        background: url(/static/img/flow05.png) no-repeat left top;
        background-size: 129px auto;
      }
      .flow h3 {
        font-size: 1.4rem;
      }
    .specialities {
      padding: 40px 20px;
    }
      .specialities::before {
        left: calc(100% / 2);
      }
  }
  @media screen and (max-width: 592px) {
    .flow {
      padding-right: 10px;
      padding-left: 80px;
    }
    .flow::before {
      left: 10px;
      width: 127px;
      height: 475px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 184px 475px;
    }
      .flow li {
        padding-left: 120px;
        min-height: 140px;
      }
      .flow li:nth-of-type(1) {
        min-height: 110px;
        background: url(/static/img/flow01.png) no-repeat left top;
        background-size: 110px auto;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
        background-size: 110px auto;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
        background-size: 110px auto;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
        background-size: 110px auto;
      }
      .flow li:nth-of-type(5) {
        min-height: 99px;
        background: url(/static/img/flow05.png) no-repeat left top;
        background-size: 110px auto;
      }
      .flow h3 {
        font-size: 1.125rem;
      }
      .flow p {
        line-height: 1;
      }
    .specialities {
      font-size: 0.875rem;
    }
  }
  @media screen and (max-width: 520px) {
    .flow::before {
      width: 127px;
      height: 505px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 196px 505px;
    }
    .flow li {
      min-height: 150px;
      height: 150px;
    }
      .flow li:nth-of-type(1) {
        min-height: 0;
        height: 110px;
      }
      .flow li:nth-of-type(5) {
        min-height: 0;
        height: 99px;
      }
    .specialities {
      padding: 30px 20px 40px;
    }
      .specialities::before {
        width:  40px;
        height: 40px;
      }
  }
  @media screen and (max-width: 420px) {
      .flow h3 {
        font-size: 1rem;
      }
      .flow p {
        font-size: .75rem;
      }
  }
  @media screen and (max-width: 350px) {
    .flow {
      padding-left: 65px;
    }
    .flow::before {
      left: 5px;
      width: 104px;
      height: 405px;
      background: url(/static/img/flow06.png) no-repeat left bottom;
      background-size: 157px 405px;
    }
      .flow li {
        padding-left: 90px;
        min-height: 120px;
        height: 120px;
      }
      .flow li:nth-of-type(1) {
        min-height: 80px;
        height: 80px;
        background: url(/static/img/flow01.png) no-repeat left top;
        background-size: 86px auto;
      }
      .flow li:nth-of-type(2) {
        background: url(/static/img/flow02.png) no-repeat left top;
        background-size: 86px auto;
      }
      .flow li:nth-of-type(3) {
        background: url(/static/img/flow03.png) no-repeat left top;
        background-size: 86px auto;
      }
      .flow li:nth-of-type(4) {
        background: url(/static/img/flow04.png) no-repeat left top;
        background-size: 86px auto;
      }
      .flow li:nth-of-type(5) {
        min-height: 79px;
        height: 79px;
        background: url(/static/img/flow05.png) no-repeat left top;
        background-size: 86px auto;
      }
  }

/* others */
  #others {
    padding: 95px 196px 80px 60px;
  }
    #others header p {
      font-size: 1.5rem;
      text-align: center;
    }
    .other-cards {
      margin: 0 20px 40px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-gap: 20px;
      justify-items: center;
    }
      .other-cards a {
        text-decoration: none;
      }
    .others-card {
      max-width: 500px;
    }
      .others-card img {
        width: 100%;
      }
      .others-card h3 {
        margin: 0;
        font-size: 1.125rem;
        color: #000;
      }
      .others-card h3 span {
        display: block;
        font-size: 1.5rem;
      }
      .others-card .link-category {
        float: right;
        margin: 0;
        width: 120px;
      }
      .others-card p {
        margin: 5px 0 0;
        color: #000;
        font-size: .875rem;
        line-height: 1.28;
      }
      /* only IE */
      @media all and (-ms-high-contrast: none) {
        .other-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        .others-card {
          margin: 0 10px 20px;
          width: 350px;
        }
      }
    .other-banners {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
    }
    .other-banners a {
      width: calc(50% - 16px);
    }
  @media screen and (max-width: 1402px) {
    .other-banners a {
      /* width: 50%; */
    }
  }
  @media screen and (max-width: 1032px) {
    .other-banners {
      padding: 0;
      display: block;
    }
    .other-banners a {
      margin: 0 auto;
      display: block;
      max-width: 500px;
      width: 100%;
    }
    .other-banners a + a {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    #others {
      padding: 80px 20px 30px;
    }
    #others header p {
      font-size: 1rem;
      text-align: center;
    }
    .other-cards {
      margin: 0 0 40px;
    }
    .others-card {
      margin-bottom: 30px;
      width: 100%;
      max-width: 100%;
    }
      .others-card h3 {
        font-size: 1rem;
      }
      .others-card h3 span {
        font-size: 1.125rem;
      }
      .others-card .link-category {
        width: 85px;
      }
      .others-card p {
        font-size: .75rem;
      }
    .other-banners a {
      max-width: 100%;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .other-cards {
      display: block;
    }
  }

/* contact */
  #contact {
    padding: 95px 196px 80px 60px;
    background-color: #f08c00;
    text-align: center;
  }
    #contact header h2,
    #contact header p {
      color: #fff;
    }
    #contact header p {
      font-size: 1.5rem;
    }
  @media screen and (max-width: 880px) {
    #contact header p {
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: 768px) {
    #contact {
      padding: 80px 20px 30px;
    }
    #contact header p {
      font-size: 0.875rem;
    }
  }

/* company */
  #company {
    position: relative;
    padding-top: 45px;
    border-top: 8px solid #fff;
  }
    #gmap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 600px;
    }
    .profile {
      position: relative;
      z-index: 3;
      margin-left: 60px;
      padding: 20px;
      width: 42%;
      background-color: #fff;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.25);
      transform: translateY(75px);
    }
      .profile h2 {
        font-size: 1.125rem;
      }
      .profile h2 span {
        font-size: 2.25rem;
        line-height: 1.2;
      }
      .profile dl {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        font-size: .875rem;
        line-height: 1.14;
      }
      .profile dt {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        width: 6em;
        color: #631a19;
      }
      .profile dt span {
        display: inline-block;
        width: 100%;
        text-align-last: justify;
        text-justify: inter-ideograph;
      }
        .profile dt::after {
          content: '：';
        }
      .profile dd {
        margin: 0 0 8px;
        width: calc(100% - 6em);
        color: #333;
      }
    .addr {
      position: relative;
      z-index: 2;
      padding: 25px 25px 25px calc(100% - 42% - 95px);
      width: calc(100% - 136px);
      background-color: #f2f6fa;
    }
      .addr > div {
        font-size: 1rem;
        text-align: right;
      }
      .addr > div > p {
        margin-bottom: 0;
      }
      .addr > div span {
        color: #f08c00;
        font-weight: bold;
      }
    .ctrl {
      position: absolute;
      top: 118px;
      right: 186px;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 200px;
      height: 25px;
      border: 2px solid #631a19;
      border-radius: 12.5px;
    }
      .ctrl li {
        width: 50%;
        background-color: #fff;
        font-size: .875rem;
        color: #631a19;
        text-align: center;
        /* cursor: pointer; */
      }
      .ctrl li:first-of-type {
        border-radius: 12.5px 0 0 12.5px;
      }
      .ctrl li:last-of-type {
        border-radius: 0 12.5px 12.5px 0;
      }
      .ctrl li.current {
        background-color: #631a19;
        color: #fff;
      }
  @media screen and (max-width: 962px) {
    #company {
      padding-top: 20px;
    }
    .profile {
      margin-left: 20px;
      margin-bottom: 400px;
      width: calc(100% - 40px - 136px);
      transform: translateY(0);
    }
    .addr {
      padding: 20px;
    }
      .addr > div {
        text-align: center;
      }
    .ctrl {
      top: auto;
      right: 150px;
      bottom: 560px;
    }
  }
  @media screen and (max-width: 768px) {
    #company {
      padding-top: 0;
    }
    .profile {
      width: calc(100% - 40px);
      padding: 10px;
    }
      .profile h2 {
        font-size: 1rem;
      }
      .profile h2 span {
        font-size: 2rem;
      }
      .profile dl {
        display: block;
        font-size: .875rem;
      }
      .profile dt {
        display: block;
        margin-bottom: 0;
        width: auto;
      }
      .profile dt span {
        text-align-last: left;
      }
        .profile dt::after {
          content: '';
        }
      .profile dd {
        width: calc(100%);
      }
    .addr {
      padding: 10px;
      width: 100%;
    }
      .addr > div {
        padding: 0 10px;
        text-align: center;
      }
    .ctrl {
      right: 20px;
      bottom: 570px;
    }
  }
  @media screen and (max-width: 480px) {
    .ctrl {
      bottom: 585px;
    }
  }
</style>
